import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Container } from '@mui/system';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { userRequest } from '../requestMethods';
import DeleteIcon from '@mui/icons-material/Delete';

import EditIcon from '@mui/icons-material/Edit';
import SearchAddBar from '../components/SearchAddBar';
import { useNavigate } from 'react-router';
import { setLoading } from '../store/userSlice';
import { useDispatch } from 'react-redux';
import { deleteMachine } from '../store/apiCalls';

export default function Lignes() {
    const [machine, setMachine] = React.useState([])
    const [search, setsearch] = React.useState("")
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);
    const [id, setId] = React.useState("");


    const getData = async (uri) => {
        dispatch(setLoading(true));
        try{
           
            const { data } = await userRequest.get(uri);
            setMachine(data);
            dispatch(setLoading(false));

        }catch(e){
            dispatch(setLoading(false));
        }
    };
    function searchH(text) {
        setsearch(text)
    }

    React.useEffect(() => {
        if(search.length >= 3){
            getData(`/machine/search/${search}`);
        }else if(search.length === 0){
            getData(`/machine/last`);
        }
    }, [search]);

    const deleteAgree = () => {
        deleteMachine(dispatch, id).then(d => {
            setMachine(machine.filter(t => t._id != id))
            setId("")
        }).catch(e => console.log(e));
        setOpen(false);
    }

    const handleClose = () => {
        setOpen(false);
        setId("")
    };
    const handleClickOpen = (id) => {
        setOpen(true);
        setId(id)

    };
    return (
        <Container component="main" maxWidth="md">
             <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure to remove this Machine?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    This Machine and her checklists will be permanently deleted and will not be refunded
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={deleteAgree} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
            <Box
                sx={{
                    marginTop: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <h1 className='title' >Machines</h1>
                <SearchAddBar searchHandler={searchH} uri="/machines/add"/>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="center">Ligne</TableCell>
                                <TableCell align="center">QR Code</TableCell>
                                <TableCell align="center">Postion</TableCell>
                                <TableCell align="center">Ref</TableCell>
                                <TableCell align="center">Number Of Tasks</TableCell>
                                <TableCell align="center">Created At</TableCell>
                                <TableCell align="center">Edit</TableCell>
                                <TableCell align="center">Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {machine.map((row) => (
                                <TableRow
                                    key={row._id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="center">{row.ligne?.name}</TableCell>
                                    <TableCell align="center">{row._id}</TableCell>
                                    <TableCell align="center">{row.position}</TableCell>
                                    <TableCell align="center">{row.ref?.name}</TableCell>
                                    <TableCell align="center">{row.tasks.length}</TableCell>
                                    <TableCell align="center">{row.createdAt}</TableCell>
                                    <TableCell align="center"><Button style={{backgroundColor: "orange"}} onClick={e => navigate(`/machines/edit/${row._id}`)} variant="contained" endIcon={<EditIcon />}>
                                        Edit
                                    </Button>
                                    </TableCell>
                                    <TableCell align="center"><Button style={{ backgroundColor: "red" }} onClick={e => handleClickOpen(row._id)} variant="contained" endIcon={<DeleteIcon />}>
                                        Delete
                                    </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Container>
    );
}
