// file = Html5QrcodePlugin.jsx
import { Html5QrcodeScanner ,Html5QrcodeScanType,Html5QrcodeSupportedFormats} from 'html5-qrcode';
import { useEffect } from 'react';

const qrcodeRegionId = "html5qr-code-full-region";

// Creates the configuration object for Html5QrcodeScanner.
const formatsToSupport = [
    Html5QrcodeSupportedFormats.QR_CODE,
    // Html5QrcodeSupportedFormats.UPC_A,
    // Html5QrcodeSupportedFormats.UPC_E,
    // Html5QrcodeSupportedFormats.UPC_EAN_EXTENSION,
  ];
const createConfig = (props) => {
    let config = {
        rememberLastUsedCamera: true,
        formatsToSupport: formatsToSupport,
  // Only support camera scan type.
  supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
  aspectRatio :1
    };
    if (props.fps) {
        config.fps = props.fps;
    }
    if (props.qrbox) {
        config.qrbox = props.qrbox;
    }
    if (props.aspectRatio) {
        config.aspectRatio = props.aspectRatio;
    }
    if (props.disableFlip !== undefined) {
        config.disableFlip = props.disableFlip;
    }
    return config;
};

const Html5QrcodePlugin = (props) => {

    useEffect(() => {
        // when component mounts
        const config = createConfig(props);
        const verbose = props.verbose === true;
        // Suceess callback is required.
        if (!(props.qrCodeSuccessCallback)) {
            throw "qrCodeSuccessCallback is required callback.";
        }
        console.log("eeee");
        if(document.getElementById(qrcodeRegionId)){
            const html5QrcodeScanner = new Html5QrcodeScanner(qrcodeRegionId, config, true);
            html5QrcodeScanner.render(props.qrCodeSuccessCallback, props.qrCodeErrorCallback);
    
            // cleanup function when component will unmount
            return () => {
                html5QrcodeScanner.clear().catch(error => {
                    // console.error("Failed to clear html5QrcodeScanner. ", error);
                });
            };

        }
    }, []);

    return (
        <div style={{width:400,marginBottom:10}} id={qrcodeRegionId} />
    );
};

export default Html5QrcodePlugin;