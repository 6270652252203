import './App.css';
import { Routes, Route, Link, Navigate } from "react-router-dom";
import Login from './pages/Login';
import Home from './pages/Home';
import NavBar from './components/NavBar';
import { ThemeProvider } from '@mui/material';
import { darkTheme } from "./themes/Dark";
import TaskVerifie from './pages/TaskVerifie';
import Check from './pages/Check';
import { useDispatch, useSelector } from 'react-redux';
import NotificationAndLoading from './components/NotificationAndLoading';
import BackButton from './components/BackButton';
import Lignes from './pages/Lignes';
import AddLigne from './pages/AddLigne';
import EditLigne from './pages/EditLigne';
import Users from './pages/Users';
import AddUser from './pages/AddUser';
import EditUser from './pages/EditUser';
import TasksCheck from './pages/TasksCheck';
import TasksCheckerEdit from './pages/TasksCheckerEdit';
import Tasks from './pages/Tasks';
import NotFound from './pages/NotFound';
import Footer from './components/Footer';
import Chaines from './pages/Chaines';
import Dashboard from './pages/Dashboard';
import { useEffect, useState } from 'react';
import { setQrCode } from './store/userSlice';
import Types from './pages/Types';
import AddType from './pages/AddType';
import EditType from './pages/EditType';
import DataDown from './pages/DataDown';
import History from './pages/History';
import TaskVerifieCam from './pages/TaskVerifieCam';

function App() {

  const [barcodeRead, setBarcodeRead] = useState("");
  const [interval, setinterval] = useState(null);
  const dispatch = useDispatch();
  // const qrCode = useSelector((state) => state.qrCode);



  const barcodeReaded = () => {
    if (barcode.length > 1) {
      setBarcodeRead(barcode);
      dispatch(setQrCode(barcode));
    } else {
      barcode.data = "";
    }
  };

  var barcode = '';
  useEffect(() => {
    window.addEventListener('keydown', function (evt) {
      // console.log(evt.code);
      if (interval)
          clearInterval(interval);
      if (evt.code == 'Enter') {
          if (barcode)
          barcodeReaded(barcode);
          // console.log(barcode);
          barcode = '';
          return;
      }
      if (evt.key != 'Shift')
          barcode += evt.key;
      setinterval(setInterval(() => barcode = '', 1000));
  });
  }, []);
  const user = useSelector((state) => state.user);
  return (
    <div className="App">
      {/* <div>Readed: {barcodeRead}</div> */}
      {/* <ThemeProvider theme={darkTheme}> */}
        <NotificationAndLoading />
        {
          user.currentUser.accessToken && <NavBar />
        }
        <BackButton />
        <Routes>
          <Route path="/NotFound" element={<NotFound />} /> 
          <Route path="login" element={user.currentUser.accessToken == null ? <Login /> : <Navigate replace to="/" />} />
          <Route path="check/:id" element={user.currentUser.accessToken != null ? <Check /> : <Navigate replace to="/login" />} />
          <Route path="/downloads" element={user.currentUser.accessToken != null ? <DataDown /> : <Navigate replace to="/login" />} />
          <Route path="/cam" element={user.currentUser.accessToken != null ? <TaskVerifieCam /> : <Navigate replace to="/login" />} />
          <Route path="/" element={user.currentUser.accessToken != null ? <Dashboard /> : <Navigate replace to="/login" />} />

          {/* <Route path="/" element={<Dashboard />} /> */}


          <Route path="/dashboard" element={user.currentUser.isAdmin ? <Dashboard /> : <Navigate replace to="/NotFound" />} />
          <Route path="/lignes" element={user.currentUser.isAdmin ? <Chaines /> : <Navigate replace to="/NotFound" />} />
          <Route path="/machines" element={user.currentUser.isAdmin ? <Lignes /> : <Navigate replace to="/NotFound" />} />
          <Route path="/users" element={user.currentUser.isAdmin ? <Users /> : <Navigate replace to="/NotFound" />} />
          <Route path="/tasks" element={user.currentUser.isAdmin ? <Tasks /> : <Navigate replace to="/NotFound" />} />
          <Route path="/types" element={user.currentUser.isAdmin ? <Types /> : <Navigate replace to="/NotFound" />} />
          <Route path="/taskscheck" element={user.currentUser.isAdmin ? <TasksCheck /> : <Navigate replace to="/NotFound" />} />


          <Route path="/history/:idd" element={user.currentUser.isAdmin ? <History /> : <Navigate replace to="/NotFound" />} />
         
          <Route path="/machines/add" element={user.currentUser.isAdmin ? <AddLigne /> : <Navigate replace to="/NotFound" />} />
          <Route path="/users/add" element={user.currentUser.isAdmin ? <AddUser /> : <Navigate replace to="/NotFound" />} />
          <Route path="/types/add" element={user.currentUser.isAdmin ? <AddType /> : <Navigate replace to="/NotFound" />} />
          
          <Route path="/machines/edit/:id" element={user.currentUser.isAdmin ? <EditLigne /> : <Navigate replace to="/NotFound" />} />
          <Route path="/types/edit/:id" element={user.currentUser.isAdmin ? <EditType /> : <Navigate replace to="/NotFound" />} />
          <Route path="/users/edit/:id" element={user.currentUser.isAdmin ? <EditUser /> : <Navigate replace to="/NotFound" />} />
          <Route path="/taskschecker/edit/:id" element={user.currentUser.isAdmin ? <TasksCheckerEdit /> : <Navigate replace to="/NotFound" />} />
          <Route path="*" element={<Navigate replace to="/NotFound" />} />
        </Routes>
        <Footer/>
      {/* </ThemeProvider> */}
    </div>
  );
}


export default App;
