import { Autocomplete, Box, Button, Container, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { userRequest } from '../requestMethods';

import { useNavigate } from "react-router-dom";
import { setLoading, setNotification, setQrCode } from '../store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import Html5QrcodePlugin from '../components/Html5QrcodePlugin';
function TaskVerifieCam() {
    const [machine, setMachine] = useState([])
    const [filtedmachine, setFiltedmachine] = useState([])
    const qrCode = useSelector((state) => state.user.qrCode);
    const [barcodeRead, setBarcodeRead] = useState("");
    const [lignes, setLignes] = useState([])
    const [value, setValue] = React.useState("");
    const [filter, setFilter] = React.useState("");


    const [machinne, setmachinne] = React.useState({});
    const dispatch = useDispatch();

    const navigate = useNavigate();
    function next() {
        if(machinne){
            // var v = value.split(" - ");
            // let id = machine.find(m => m.name === v[1] && m.ligne.name === v[0])._id;
            navigate(`/check/${machinne._id}`)
        }else{
            dispatch(setNotification({
                msg:"Please choose a Ligne",
                error:true
            }));
        }
    }

    const getData = async () => {
        dispatch(setQrCode(""));

        dispatch(setLoading(true));
        try{
            const { data } = await userRequest.get(`/machine/m`);
            setMachine(data.machines);
            setLignes(data.lignes);
            setFiltedmachine(data.machines)

            dispatch(setLoading(false));

        }catch(e){
            dispatch(setLoading(false));
        }
        
      };
      useEffect(() => {
        getData();
      }, []);
      useEffect(() => {
        if(barcodeRead !== qrCode){
            setBarcodeRead(qrCode);
            console.log(qrCode);
           let mnm = machine.find(m => m._id === qrCode.replace("Enter",""))
           if(mnm){
            setmachinne(mnm);
           }
           dispatch(setQrCode(""));

        }
      }, [qrCode]);
      useEffect(() => {
        if(filter){
            if(filter.length >2){
                setFiltedmachine(machine.filter(m => m.ligne.name === filter) )
            }else{
                setFiltedmachine(machine)
            }

        }else{
            setFiltedmachine(machine)
        }
      }, [filter]);
      const onNewScanResult = (decodedText, decodedResult) => {
        // handle decoded results here
        dispatch(setQrCode(decodedText));
    };
    return (
        <Container component="main" maxWidth="md">
            <Box
                sx={{
                    marginTop: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <h1 className='title' style={{margin:0}}>Ligne Task Checker Cam</h1>

                <h3 className='machine-name' >Machine Name : {machinne.name}</h3>
                 <div className="qrScanner">
                    {/* <img className='img-qr' src='qr.png' ></img>
                    <h5 className='' >Click To Scan</h5> */}
                    <Html5QrcodePlugin
                fps={10}
                qrbox={ 200}
                disableFlip={false}
                qrCodeSuccessCallback={onNewScanResult}
            />
                 </div>
                {/* <Autocomplete
                    onChange={(event, newValue) => {
                        setFilter(newValue);
                    }}
                    options={lignes.map(d => d.name)}
                    sx={{ maxWidth: 400,width: "80%" }}
                    renderInput={(params) => <TextField {...params} label="Select Ligne" />}
                /> */}
                {/* <Autocomplete
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    options={filtedmachine.map(d => `${d.ligne.name} - ${d.name}`)}
                    sx={{ maxWidth: 400,width: "80%", marginTop:3}}
                    renderInput={(params) => <TextField {...params} label="Select Machine To Verifie Tasks" />}
                /> */}
                 {/* <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="QR"
                    label="QR"
                    type="text"
                    id="QR"
                    autoComplete="QR"
                    onChange={(e) => setValue(e.target.value)}
                /> */}
                {
                    machinne._id && (
                    <Button
                        type="submit"
                        className='blue vt'
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={e => next() }
                    >
                        Check Now
                    </Button>
                    )
                }
            </Box>
        </Container>
    );
}

export default TaskVerifieCam