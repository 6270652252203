import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router';

function NotFound() {
    const navigate = useNavigate();
    return (
        <div className='notF'>
            <h1>404</h1>
            <div className="">
                <div className="">
                    <div className=""></div>
                </div>
            </div>
            <div className="info">
                <h2>We can't find that page</h2>
                <p>We're fairly sure that page used to be here, but seems to have gone missing.</p>
                <Button
                        type="submit"
                        className='blue vt'
                        variant="contained"
                        onClick={(e) => navigate(`/`) }
                    >
                        Go Home
                    </Button>
            </div>
        </div>
    )
}

export default NotFound